<template lang="pug">
  main.white-bg
    .inside-content
      .container
        article.article
          .article__header
            .article__header-info
              .article__header-content
                h1 Расчет радиаторов отопления онлайн

              .article__header-img
                img(src="~images/blog/radiator.svg" style="margin: 15% auto; width: 40%")
                //-                             0        768        992          1280
                //+img("i/article-img", "jpg", [719,       943,       1231,         1280], "")

          .article__content
            .article__sidebar
              .article__meta
                .article__meta-item
                  span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                  span.article__meta-item-text Время чтения 4 минуты

            .article__content-text

              h2 Расчет радиаторов отопления онлайн
              p В советское время выбор радиатора (прибора отопления) был предопределен. Вот вам чугунная батарея – грейтесь. Сегодня выбор настолько велик, что можно запутаться в маркетинговом шуме. На нашем сайте вы можете найти конвекторы, радиаторы стальные панельные и секционные, с нижним подключением и с боковым, со встроенным вентилем и без. Дизайн радиатора может быть любой – главное, его правильно подобрать.

              h2 Как правильно подбирать радиаторы?
              p Радиаторы подбирайте на основании результатов расчета теплопотерь. О том, как на нашем сайте сделать расчет теплопотерь –&nbsp;
                a(@click="goHeatloss" style="text-decoration: underline") читайте здесь.
              p Когда теплопотери в комнате известны, идем в справочник приборов отопления и выбираем такой радиатор, чтобы его теплоотдача (мощность в Ваттах) перекрывала значение теплопотерь. Не хватает одного, добавляем еще один. И так до тех пор, пока тепловой баланс в помещении не выйдет в плюс.
              p Справочник проектировщика нам говорит, что прибор надо подбирать так, чтобы избыток тепла от него не превышал 60 Вт. Но это достаточно бессмысленное требование, выросшее из Советского планового хозяйства, когда за все приборы отопления платило государство. Сейчас платим мы, и выбираем, как хотим: кто-то по ширине окна подбирает, кто-то хочет, чтобы все приборы в комнате были одного размера. Наша с вами задача — сделать так, чтобы тепла хватало, а всё, что больше, отрежет термостат.

              blockquote
                h2 Теплоотдача прибора отопления должна быть подтверждена протоколами испытаний
                p Производитель обязан сделать испытания в аккредитованной лаборатории
              h2 Проверенные и непроверенные радиаторы
              p В наших справочниках только проверенные производители радиаторов, у которых теплоотдача подтверждена реальными сертифицированными испытаниями в аккредитованной лаборатории.
              p Некоторые производители на упаковке и в паспорте на прибор отопления указывают завышенную теплоотдачу. Особенно, этим грешат китайские производители. Перед покупкой, не поленитесь зайти на сайт производителя и посмотреть протоколы испытаний. Если протоколов испытаний нет - стоит задуматься.
              p В нашей стране есть Ассоциация производителей приборов отопления (АПРО), которая борется с обманом со стороны недобросовестных поставщиков. Они делают контрольные закупки радиаторов. В специальной аккредитованной лаборатории проводят испытания - замеряют реальную теплоотдачу и сравнивают ее с той, что указана на упаковке. Если цифры отличаются, то принимают меры. На их сайте вы можете найти список всех проверенных производителей приборов отопления.

              p
                img(src="~images/blog/article-3-1.jpg" loading="lazy" alt="")

                //-                             0        768        992          1280
                //+img("i/article-3-1", "jpg", [674,       674,       674,         674], "")

              h2 Паспортная и действительная теплоотдача радиаторов

              p В нашем калькуляторе, вы увидите и паспортную, и действительную теплоотдачу для каждого радиатора. И эти цифры могут отличаться существенно.
              p С паспортной теплоотдачей мы разобрались – это теплоотдача, измеренная в испытательной лаборатории при стандартных экспериментальных условиях. Производитель ее указывает в паспорте и на упаковке.
              p Действительная теплоотдача – это количество тепла, которое отдает радиатор в реальной жизни. Ведь в вашем доме, температура теплоносителя и температура воздуха могут быть совершенно другими, чем в лаборатории.
              p Многие покупатели считают, что прибор отопления — он как лампочка, если 100 Вт, то всегда 100 Вт. Наш калькулятор позволяет раскрыть им глаза и снять вопросы типа «почему так много и дорого».

              h2 Определение расхода теплоносителя через прибор отопления
              p После того, как вы выбрали прибор, мы подбираем такой расход, чтобы температура в обратке была на 15 градусов ниже, чем температура в подаче.
                |  Именно такой перепад температур считают оптимальным производители настенных котлов и насосных модулей. Но расход не может быть каким угодно.
                |  Если в теплых полах определяющим для расхода является скорость витания, то здесь минимально допустимый расход определяется соображениями балансировки.
                |  Если расход будет слишком мал — балансировочный вентиль на приборе будет в положении «почти закрыто», что плохо.
                |  У нас есть &nbsp;
                a(href="https://www.youtube.com/watch?v=xjuo0-Y7zCI" target="_blank") ролик, посвященный авторитету вентиля
                |, обязательно посмотрите его, чтобы понимать, в чем суть. Для любого прибора минимально допустимым мы считаем расход в 0.65 литров в минуту, и сделать его ниже, мы вам не дадим.

              p
                img(src="~images/blog/article-3-2.jpg" loading="lazy" alt="")

                //-                             0        768        992          1280
                //+img("i/article-3-2", "jpg", [471,       471,       471,         471], "")

              h2 Выводы и рекомендации
              p 1. Наш калькулятор позволяет подбирать модели радиаторов разных брендов на основании расчета теплопотерь.
              p 2. Вы сможете смоделировать разные режимы работы радиатора, чтобы подобрать оптимальный и скономить на оборудовании.

              .download(id="lightgallery")
                .download__content
                  .download__text
                    | 3. Результат расчета и подбора радиаторов формируется в виде ведомости, оформленной по ГОСТ. В штампе вы можете указать свое имя, как разработчика.

                  a.download__text-link(href="/heating.pdf", target="_blank") скачать (.PDF 70kb)
                .download__img
                  enlargeable-image(:src="downloadImg" :src_large="downloadImg" animation_duration="300")
                    span
                      img(src="~/images/blog/article-doc-3-small.png", alt="")

                    //-                             0        768        992          1280
                    //+img("i/article-doc-3", "jpg", [221,       221,       471,         221], "")

              .article__register
                a.button.button_green(@click="goSignUp") Регистрация

              //.article__likes
                .article__likes-title Этот материал был вам полезен?
                .article__likes-buttons
                  button.article__likes-button(type="button")
                    span.article__likes-button-text Да
                    span.article__likes-button-icon <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".2" d="M3 9.75h4.5v9.75H3a.75.75 0 01-.75-.75V10.5A.75.75 0 013 9.75z" fill="#696E8A"/><path d="M21.74 7.51a2.25 2.25 0 00-1.69-.76H15v-1.5a3.75 3.75 0 00-3.75-3.75.75.75 0 00-.67.41L7.04 9H3a1.5 1.5 0 00-1.5 1.5v8.25a1.5 1.5 0 001.5 1.5h15.93a2.25 2.25 0 002.23-1.97l1.12-9a2.25 2.25 0 00-.54-1.77zM3 10.5h3.75v8.25H3V10.5zm17.8-1.4l-1.13 9a.75.75 0 01-.74.65H8.25V9.93l3.44-6.89a2.25 2.25 0 011.8 2.21V7.5a.75.75 0 00.76.75h5.8a.75.75 0 01.74.84z" fill="#696E8A"/></svg>
                  button.article__likes-button(type="button")
                    span.article__likes-button-text Нет
                    span.article__likes-button-icon <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".2" d="M3 14.25h4.5V4.5H3a.75.75 0 00-.75.75v8.25a.75.75 0 00.75.75z" fill="#696E8A"/><path d="M21.74 16.49a2.25 2.25 0 01-1.69.76H15v1.5a3.75 3.75 0 01-3.75 3.75.75.75 0 01-.67-.41L7.04 15H3a1.5 1.5 0 01-1.5-1.5V5.25A1.5 1.5 0 013 3.75h15.93a2.25 2.25 0 012.23 1.97l1.12 9a2.25 2.25 0 01-.54 1.77zM3 13.5h3.75V5.25H3v8.25zm17.8 1.4l-1.13-9a.75.75 0 00-.74-.65H8.25v8.82l3.44 6.89a2.25 2.25 0 001.8-2.21V16.5a.75.75 0 01.76-.75h5.8a.75.75 0 00.74-.84z" fill="#696E8A"/></svg>

        section.related
          .related__title Другие статьи

          .prev-next
            a.prev-next__item(@click="goWhy") Предыдущая
            a.prev-next__item(@click="goFloor") Следующая

          flickity.related__grid(ref="flickity" :options="flickityOptions")
            article.article-card
              a.article-card__img(@click="goFloor")
                img(src="~images/blog/warm-floor.svg" style="width: 50%")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goFloor") Расчет теплого пола
                .blog-desc
                  p Водяной теплый пол — это залитые в бетон трубы. Если что-то сделано неправильно, устранение ошибки обойдется очень дорого.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 5 минут

                  a.blog-card__read-more(@click="goFloor")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goWhy")
                img(src="~images/blog/article-img.png")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goWhy") Зачем нужны расчеты
                .blog-desc
                  p Думаем, ни для кого не секрет, что подавляющее большинство частных домов строится не только без проекта, но и вообще без расчетов.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 10 минут

                  a.blog-card__read-more(@click="goWhy")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goHeatloss")
                img(src="~images/blog/house.svg" style="width: 60%")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goHeatloss") Расчет теплопотерь
                .blog-desc
                  p Наш онлайн калькулятор теплопотерь позволяет сделать расчет в двух вариантах: Упрощенный или Подробный.
                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 3 минуты

                  a.blog-card__read-more(@click="goHeatloss")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>
</template>
<script>
import Flickity from 'vue-flickity'
import navigationMixin from 'mixins/navigation'
import EnlargeableImage from '@diracleo/vue-enlargeable-image'

export default {
  name: 'RaschetRadiatorov',
  mixins: [navigationMixin],
  data () {
    return {
      flickityOptions: {
        groupCells: true,
        wrapAround: false,
        contain: true,
        autoPlay: false,
        adaptiveHeight: false,
        prevNextButtons: false,
        pageDots: false,
        resize: false
      },
      downloadImg: require('../../assets/images/blog/article-doc-3.png')
    }
  },
  components: {
    Flickity,
    EnlargeableImage
  },
  metaInfo: {
    title: 'Расчет и подбор радиаторов отопления. Онлайн-калькулятор.',
    meta: [
      {
        name: 'description',
        content: ' Подбор панельных и секционных радиаторов по мощности, на основе результатов расчета теплопотерь.'
      }
    ]
  }
}
</script>
